import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss']
})
export class InputRadioComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() type = 'radio';
  @Input() name: string;
  @Input() id: string;
  @Input() value: string;
  @Input() options: any = {};
  @Input() checked: any;

  ngOnInit() {
    if ( this.checked ) {
      this.fireChange();
    }
  }

  fireChange() {
    this.parentForm.get(this.fCName)?.setValue(this.value ? this.value : this.name, {emitEvent: this.options.emit ? this.options.emit : false});
  }
}
