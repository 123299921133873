import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(
    code: string,
    format: 'wide' | 'narrow' = 'narrow',
    locale?: string
  ): any {
    switch (code?.toLocaleUpperCase()) {
      // Peso colombiano
      case 'COP':
      case 'MAD':
        return code;

      default:
        return getCurrencySymbol(code, format, locale);
    }
  }
}
