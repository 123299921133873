import { environment as environmentStaging } from './environment.staging';
let localEnvironment = {
  api_backend_url: 'https://middlestaging-internal.lleego.es',
  dev_api_url: 'https://testapi-internal.lleego.es',
};

export let environment = {
  ...environmentStaging,
  ...localEnvironment,
};
