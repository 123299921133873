import { Injectable, Injector } from '@angular/core';
import { UserService } from '../user/user.service';
import { FrogedService } from './froged.service';
import { EMPTY, Observable } from 'rxjs';

export const serviceMap = {
  froged: FrogedService,
};

@Injectable({ providedIn: 'root' })
export class GenericChatService {
  service: any;
  // isChatActive: boolean = false;

  constructor(private injector: Injector, private userService: UserService) { }

  isActive(): boolean {
    return (
      this.userService.haveFrogedChatActive()
    );
  }

  set(atts: any, currentView: string): void {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.set(atts);
    }
  }

  track(eventName: string, meta: any = {}, currentView: string): void {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.track(eventName, meta);
    }
  }

  toggle(currentView: string): void {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.toggle();
    }
  }

  startConversation(message: any, currentView: string): void {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.startConversation(message);
    }
  }

  find(message: any, currentView: string): void {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.find(message);
    }
  }

  openConversation(currentView: string): void {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.openConversation();
    }
  }

  onInboxStatusUpdate(currentView: string): void {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.onInboxStatusUpdate();
    }
  }

  onInboxUpdate(currentView: string): void {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.onInboxUpdate();
    }
  }

  logout(currentView: string): void {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.logout();
    }
  }

  loadScript({ id, url }, currentView: string) {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.loadScript({ id, url });
    }
  }

  removeScript(id: string, currentView: string) {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.removeScript(id);
    }
  }

  openTicket(bookingId: string, message: string, currentView: string): void {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      this.service.openTicket(bookingId, message);
    }
  }

  getNumChatUnreadMessages$(currentView: string): Observable<any> {
    if (serviceMap.hasOwnProperty(currentView)) {
      this.service = this.injector.get<any>(serviceMap[currentView]);
      return this.service.getNumChatUnreadMessages$();
    }

    return EMPTY;
  }

}
