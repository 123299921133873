import packageInfo from '../../../../package.json';

export const VERSION: string = packageInfo.version;

export const residentAirports = {
  balear: ['PMI', 'IBZ', 'MAH'], // BL
  canary: ['ACE', 'FUE', 'SPC', 'TFN', 'TFS', 'TCI', 'VDE', 'GMZ', 'LPA'], // CN
  ceuta: ['JCU', 'SVQ', 'XRY', 'AGP'], // CE
  melilla: ['MLN'], // ML
};

export const disneyAirports: string[] = ['PAR', 'CDG', 'ORY', 'BVA'];

export const baseUrlImgAirlines =
  'https://assets.lleego.com/backend/airlines/';
export const baseUrlImgRentCar =
  'https://assets.lleego.com/backend/rent-a-car/';

export const baseUrlImgProvidersFavicon = 'https://assets.lleego.com/backend/providers/';

export const defaultLogo = 'https://assets.lleego.com/backend/logos/lleego.svg';

export const hideFooterUrls: string[] = [
  '/call-center',
  '/transport/flight/',
  '/transport/trains/',
  '/transport/cars/avail/',
  '/transport/cars/voucher/changes/',
  '/transport/voucher/changes/',
];

export const transportVertical = {
  corporate: 'corporate',
  vacational: 'vacational',
};
export const dateFormat = {
  default: {
    monthDay: 'd MMM',
    fullDate: 'dd MMM yyyy',
    fullDateWithHours: 'dd MMM yyyy HH:mm',
    shortDate: 'd/M/yy',
    dateCalendarForm: 'd-M-yyyy',
    dateVoucherCar: 'EEE, d LLL yy, HH:mm',
    dateWithDotted: 'dd.MM.yyyy HH:mm',
    shortTime: 'HH:mm',
    resultItemDetail: 'EEE d MMM',
    resultItemHeader: 'EEE, dd MMM',
    resultItemHeaderPrint: 'EEE, dd MMMM yyyy',
    bookingList: 'dd MMMM yyyy',
    bookingListWithHours: 'dd MMMM HH:mm',
    printFligth: 'EEEE d MMMM yyyy',
    bigCalendar: 'dd MMM, EEEE',
    voucherNotificationWithHours: 'EEE, d MMM HH:mm',
    voucherNotification: 'EEE d MMM , yyyy',
    voucherHeader: 'EEE, d MMM yyyy |',
    tableInfo: 'dd MMM, yyyy, HH:mm',
    budget: 'EEE, dd LLL yyyy HH:mm',
    inputCalendar: 'EEE, dd MMM',
    alerts: 'mm:ss',
    'dd/MMM/yyyy': 'dd/MMM/yyyy',
    inputCalendarMoment: 'ddd, DD MMM',
    changedDate: 'dd/MM/yyyy HH:mm:ss',
    fullDateFlightItem: 'EEEE dd MMMM yyyy',
    budgetsItemIonf: 'EE, dd MMM | HH:mm',
    multiLoading: 'dd MMM',
    day: 'd',
    differenceBetweenDates: 'yyyy-MM-dd',
    callCenterLastUpdate: 'dd MMM yyyy, HH:mm',
    genericFiltersDate: 'MMM/dd',
    bookingFormBirthdate: 'ddMMyyyy',
    timeLimitTooltip: 'EEE, dd MMM HH:mm',
  },
  en: {
    monthDay: 'MMM d',
    fullDate: 'MMM dd yyyy',
    fullDateWithHours: 'MMM dd yyyy HH:mm',
    shortDate: 'M/d/yy',
    dateCalendarForm: 'M-d-yyyy',
    dateVoucherCar: 'EEE, LLL d yy, HH:mm',
    dateWithDotted: 'MM.dd.yyyy HH:mm',
    resultItemDetail: 'EEE MMM d',
    resultItemHeader: 'EEE, MMM dd',
    resultItemHeaderPrint: 'EEE, MMMM dd yyyy',
    bookingList: 'MMMM dd yyyy',
    bookingListWithHours: 'MMMM dd HH:mm',
    printFligth: 'EEEE MMMM d yyyy',
    bigCalendar: 'EEEE MMM dd',
    voucherNotificationWithHours: 'EEE, MMM d HH:mm',
    voucherNotification: 'EEE MMM d , yyyy',
    voucherHeader: 'EEE, MMMM d yyyy |',
    tableInfo: 'dd MMM, yyyy, HH:mm',
    budget: 'EEE, dd LLL yyyy HH:mm',
    inputCalendar: 'EEE, MMM dd',
    'dd/MMM/yyyy': 'MMM/dd/yyyy',
    inputCalendarMoment: 'ddd, MMM DD',
    changedDate: 'MM/dd/yyyy HH:mm:ss',
    fullDateFlightItem: 'EEEE dd MMMM yyyy',
    budgetsItemIonf: 'EE, dd MM | HH:mm',
    genericFiltersDate: 'MMM/dd',
    bookingFormBirthdate: 'MMddyyyy',
  },
  pt: {
    voucherHeader: 'EEE, d MMMM yyyy |',
  },
};

export const routerHome = {
  homeFligthRouter: '/',
  homeCarsRouter: 'cars',
  homeTrainRouter: 'trains',
  homeCallCenter: 'call-center',
  homeCallCenterB2B: 'call-center/b2b',
  homeCallCenterB2C: 'call-center/b2c',
  homeRailApp: 'rail-app',
};

export const tabTitle = {
  quotes: 'quotes list_',
  agent: 'alerts list_',
  carsHome: 'cars_home_',
  flightsHome: 'flights home_',
  trainsHome: 'trains_home_',
  notFound: 'page not found_',
  printResult: 'print result_',
  bookingForm: 'booking form_',
  changeVoucher: 'change_',
  results: 'results_',
  voucher: 'booking',
  printVoucher: 'print voucher_',
  infoBudget: 'informative note_',
  printBudget: 'print budget_',
  budgetBookingForm: 'booking form_',
  upgradesVoucher: 'upgrades_',
  refundVoucher: 'refund_',
  login: 'login_',
  logout: 'logout_tab_',
  recoveryPassword: 'restore password_',
  confirmPassword: 'confirm password_',
  privacy: 'footer.policy privacity_',
  legal: 'footer.legal alert_',
  admin: {
    home: 'dashboard_',
    agents: 'agents_',
    agentList: 'agent_list_',
    newAgent: 'new_agent_',
    services: 'services_',
    tails: 'tails_',
    capsule: 'capsule_',
    recoverBooking: 'recover this booking_',
    bookingManagement: 'booking management_',
    reports: 'reports_',
    reportsSearch: 'reports search_',
    bookings: 'bookings_',
    dailySales: 'daily sales_',
    balance: 'recharge balance_',
    myProfile: 'my profile_',
    consolidators: 'consolidators_',
    groups: 'groups_',
    billing: 'billing_',
    quarantine: 'quarantine_',
    logs: 'logs_',
    updateQuarantine: 'set_up_quarantine_',
    configuredQuarantines: 'configured_quarantines_',
    timeLimit: 'time_limit_',
    pushNotification: 'push_notification_',
  },
  billing: {
    home: 'dashboard_',
    movements: 'movements_',
    pendingMovements: 'pending_movements_',
    aerial: 'aerial_',
    agenciesList: 'agencies_list_',
    configuration: 'configuration_',
    profile: 'my profile_',
    listOfBanks: 'list_of_banks_',
    listOfCards: 'list_of_cards_',
    refund: 'refund_',
  },
  superAdmin: {
    home: 'dashboard_',
    commands: 'commands_',
    admin_consolidators_: 'admin_consolidators_',
    consolidators_list: 'consolidators_list_',
    super_consolidators_list: 'super_consolidators_list_',
    agents_list: 'agents list_',
    create_new: 'create new_',
    bookings_: 'bookings_',
    daily_sales: 'daily sales_',
    reports_search: 'reports search_',
    billetes: 'billetes_',
    informe_de_uso: 'informe_de_uso_',
    air: 'air_',
    capsule: 'capsule_',
    erp_orders: 'erp_orders_',
    tools: 'tools_',
    uploads: 'uploads_',
    uploads_agencies: 'uploads_agencies_',
    uploads_users: 'uploads_users_',
    uploads_service_fee: 'uploads_service_fee_',
    uploads_tarifas_privadas: 'uploads_tarifas_privadas_',
    uploads_agency_rp_virtual: 'uploads_agency_rp_virtual_',
    uploads_ancillaries: 'uploads_ancillaries_',
    downloads: 'downloads_',
    download_agencies_listings: 'download_agencies_listings_',
    download_agents_listings: 'download_agents_listings_',
    download_autologin_listings: 'download_autologin_listings_',
    autologins_dummy_agency: 'autologins_dummy_agency_',
    autologins_users_type: 'autologins_users_type_',
    massive_upload: 'massive_upload_',
    redis_gui: 'redis_gui_',
    test_agency_searches: 'test_agency_searches_',
    test_agent_access: 'test_agent_access_',
    test_autologin_access: 'test_autologin_access_',
    fix_agency_login: 'fix_agency_login_',
    manage_api_operators: 'manage_api_operators_',
    test_iberia_connect: 'test_iberia_connect_',
    test_biz_rules: 'test_biz_rules_',
    ref_cond_covid: 'ref_cond_covid_',
    delete_duplicated_bookings: 'delete_duplicated_bookings_',
    stats: 'stats_',
    activate_provider: 'activate_provider_',
    activate_provider_two: 'activate_provider_two_',
    tools_corporate: 'tools_corporate_',
    update_empresas: 'update_empresas_',
    upload_travellers_faces: 'upload_travellers_faces_',
  },
  callCenter: {
    home: 'welcome_',
    b2b: 'agency_',
    b2c: 'client_',
  },
};

export const errorTPV = {
  401001: 'error_tpv_response_',
  401002: 'error_payment_declined_',
  401003: 'error_recharge_balance_but_not_emit_',
  401004: 'error_not_recharge_',
};

export const adminRol = {
  RO: 'agency',
  RSA: 'super-admin',
  RAA: 'agency',
};

export const supportedRoles: string[] = [
  'RSA',
  'RAA',
  'RA',
  'RAT',
  'RO',
  'RC',
  'RCA',
  'RCCA',
  'RF',
];

export const languages = [
  { label: 'en', value: 'en-en', languege: 'en' },
  { label: 'es', value: 'es-es', language: 'es' },
  { label: 'es (AR)', value: 'es-ar', parent: 'es-es', language: 'ar' },
  { label: 'es (CL)', value: 'es-cl', parent: 'es-es', language: 'cl' },
  { label: 'es (CO)', value: 'es-co', parent: 'es-es', language: 'co' },
  { label: 'es (MX)', value: 'es-mx', parent: 'es-es', language: 'mx' },
  { label: 'es (PE)', value: 'es-pe', parent: 'es-es', language: 'pe' },
  { label: 'pt', value: 'pt-pt', language: 'pt' },
  { label: 'fr', value: 'fr-fr', language: 'fr' },
];

export const ancillaries = [
  'Baggage',
  'HandBaggage',
  'Meal',
  'priorityboard',
  'lounge',
  'wifi',
  'Assistance',
  'seats',
];
