<ng-container [formGroup]="parentForm">
  <input
    [type]="type"
    [ngClass]="options.class"
    [value]="value ? value : name"
    [id]="
      options.id
        ? options.id + (options.placeholder ?? '')
        : value
        ? value
        : name + 'id'
    "
    [name]="fCName"
    [formControlName]="fCName"
    (change)="fireChange()"
    [checked]="
      checked
        ? checked
        : parentForm.get(fCName)?.value === (value ? value : name)
    "
  />
  <label
    *ngIf="options.label !== false"
    [for]="
      options.id
        ? options.id + (options.placeholder ?? '')
        : value
        ? value
        : name + 'id'
    "
    [class]="fCName"
    [ngClass]="{
      error: parentForm.controls[fCName].errors && options.showErrors
    }"
    [attr.data-cy]="fCName"
  >
    <div></div>
    {{ options.placeholder | translate }}
  </label>
</ng-container>
