import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'currencyCustom',
})
export class CurrencyCustomPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  transform(
    value: number | string,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string
  ): any {
    if (value === undefined || value === null) return;
    let pipe = new CurrencyPipe(this.locale, currencyCode); // DEFAULT VALUE
    switch (currencyCode?.toLocaleUpperCase()) {
      // Peso colombiano
      case 'assets/images/icons/peso-colombiano.svg':
      // Dírham marroquí
      case 'MAD':
        display = 'code';
        break;
      case 'COP':
        this.locale = 'en-en';
        break;
    }
    return pipe.transform(
      value,
      currencyCode,
      display,
      digitsInfo,
      this.locale
    );
  }
}
